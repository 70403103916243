<template>
  <div id="booking-flow" class="container pa-3">
    <back :sessionConfirmed="sessionConfirmedCheck"
          v-on:goBack="goBack">
    </back>
    <add-client-and-therapist v-if="step === 1"
        action="'addBooking'"
        :clients="clients"
        :pre-selected-client="preSelectedClient"
        :client="selectedClient"
        :therapist="selectedTherapist"
        v-on:scroll="loadMore"
        v-on:search="search"
        v-on:getAllClients="refreshClients"
        v-on:clientSelected="clientSelected"
        v-on:therapistSelected="therapistSelected"
        v-on:increment="incrementBtn"
    ></add-client-and-therapist>

    <payment-method v-if="step === 2"
                    :client="selectedClient"
                    :therapist="selectedTherapist"
                    v-on:increment="incrementBtn"
                    v-on:getPlanDetail="getPlanDetail"
                    v-on:paymentMethodDialogue="noPaymentMethodDialogue"
    >
    </payment-method>

    <session-type v-else-if="step === 3"
                  :client="selectedClient"
                  :therapist="selectedTherapist"
                  :sessionType="selectedSessionType"
                  v-on:increment="incrementBtn"
                  v-on:getSessionTypeId="getSelectedSessionTypeId"
    ></session-type>

    <slots v-else-if="step === 4"
           :session_type="sessionTypeId"
           :therapist="selectedTherapist"
           v-on:increment="incrementBtn"
           v-on:getSelectedSlot="getSelectedSlot"

    ></slots>

    <review-session-detail v-if="step === 5"
                           :sessionType="selectedSessionType"
                           :selectedSlot="selectedSlot"
                           :planType="planType"
                           :client="selectedClient"
                           :therapistId="selectedTherapist.id"
                           :planDetails="planDetail"
                           :action="actionType"
                           v-on:sessionConfirmed="sessionConfirmed"
                           v-on:increment="incrementBtn"

    >
    </review-session-detail>

    <div v-if="paymentMethodDialogue">
      <no-payment-details-dialogue
          :client="planDetail.client"
          :paymentMethodDialogue="paymentMethodDialogue"
          :planType="planType"
          v-on:closePaymentMethodDialogue="closePaymentMethodDialogue"
      ></no-payment-details-dialogue>
    </div>
  </div>
</template>

<script>
import AddClientAndTherapist from "@/components/main/addBooking/AddClientAndTherapist";
import Back from "@/components/main/addBooking/components/back";
import PaymentMethod from "@/components/main/addBooking/paymentMethod/paymentMethod";
import SessionType from "@/components/main/addBooking/sessionType";
import Slots from "@/components/main/addBooking/slots";
import ReviewSessionDetail from "@/components/main/addBooking/reviewSessionDetails";
import NoPaymentDetailsDialogue from "@/components/main/addBooking/components/NoPaymentDetailsDialogue";
import TherapyHelper from "@/helpers/app/therapyHelper";
export default {
  name: "AddBookingFlow",
  components: {
    NoPaymentDetailsDialogue,
    ReviewSessionDetail, Slots, SessionType, PaymentMethod, Back, AddClientAndTherapist},
  data() {
    return {
      disableSubmitButton: false,
      client_skip: 0,
      client_take: 10,
      client_keyword: '',
      clients: [],
      selectedClient: null,
      selectedTherapist:null,
      isCallbackActive: false,
      step: 1,
      selectedSessionType: {},
      sessionTypeId: null,
      selectedSlot: {},
      sessionConfirmedCheck: false,
      paymentMethodDialogue: false,
      planType: "self_pay",
      clientObj:[],
      isReady: false,
      planDetail: null,
      preSelectedClient: null,
      actionType: "book",
      clientId: 0,
    }
  },
  created() {
    this.clientId = this.$route.query.client_id ? this.$route.query.client_id : 0;
    this.getClients();
  },

  methods: {
    refreshClients() {
      this.clientId = 0;
      this.preSelectedClient = null;
      this.selectedClient = null;
      this.selectedTherapist = null;
      this.getClients();
    },
    getClients() {
      this.isCallbackActive = true;
      this.$http.get(this.url+'booking/clients', {
        params: {
          skip: this.client_skip,
          take: this.client_take,
          keyword: this.client_keyword,
          client_id: this.clientId
        }
      }).then(response => {
        if (this.clientId > 0) {
          this.preSelectedClient = response.data.users[0];
        }
        if ( this.client_skip === 0 ) {
          this.clients = response.data.users;
        } else {
          this.clients = this.clients.concat(response.data.users);
        }
        this.isCallbackActive = false;
      }).catch(error => {
        this.isCallbackActive = false;
      });
    },
    loadMore(type) {
      if (!this.isCallbackActive && type === 'client') {
        this.client_skip = this.client_skip + this.client_take;
        this.getClients();
      }
    },
    search(query, type) {
      if (type === 'client')
      {
        this.client_skip = 0;
        this.client_keyword = query;
        this.getClients();
      }
    },
    clientSelected(client) {
      this.selectedClient = client;
    },
    therapistSelected(therapist) {
      this.selectedTherapist = therapist;
    },

    getSelectedSessionTypeId(sessionType){
      this.selectedSessionType = sessionType;
      this.sessionTypeId = sessionType.id;
    },

    incrementBtn() {
      if (this.isMessaging() && this.step === 3) {
        this.step = 5;
      } else {
        this.step++;
      }
    },

    goBack(){
      if (this.step === 1) {
        window.history.back();
      } else if (this.isMessaging() && this.step === 5) {
        this.step = 3;
      } else {
        this.step--;
      }
    },

    getSelectedSlot(slot){
      this.selectedSlot = slot;
    },

    sessionConfirmed(){
      this.sessionConfirmedCheck = true;
    },
    getPlanDetail(plan){
      this.planDetail = plan;
      this.planType = plan.plan_type;
    },
    noPaymentMethodDialogue(){
      this.paymentMethodDialogue = true;
      this.step = 1;
    },
    closePaymentMethodDialogue(){
      this.paymentMethodDialogue = false;
    },
    initializePreSelectedClient(client) {
      console.log('listen');
      this.preSelectedClient = client;
    },
    isMessaging(){
      return this.selectedSessionType.method === TherapyHelper.sessionTypeMethodCoaching
    },
  },
}
</script>
